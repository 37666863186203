import * as React from 'react'
import { useDispatch } from 'react-redux'
import ScrollAnimation from 'react-animate-on-scroll'
import { FiArrowRight } from 'react-icons/fi'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { set } from '../reducers/usecase'
import Layout from '../layouts/layout'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/data.module.css'

const Data = () => {
  const dispatch = useDispatch();
  const openLayer = e => {
    e.preventDefault()
    dispatch(set(e.target.dataset.param))
  }
  
  const { t } = useTranslation();
  const forecast = {
    title: t('dataElement1Title'),
    body: t('dataElement1Body'),
    items: [
      t('dataElement1Item1'),
      t('dataElement1Item2'),
      t('dataElement1Item3'),
    ]
  }
  const service = {
    title: t('dataElement2Title'),
    body: t('dataElement2Body'),
    items: [
      t('dataElement2Item1'),
      t('dataElement2Item2'),     
    ]
  }
  const data = {
    title: t('dataElement3Title'),
    body: t('dataElement3Body'),
    items: [
      t('dataElement3Item1'),
      t('dataElement3Item2'),
      t('dataElement3Item3'),
    ]
  }
  const dap = {
    title: t('dataElement4Title'),
    body: t('dataElement4Body'),
    items: [
      t('dataElement4Item1'),
      t('dataElement4Item2'),
      t('dataElement4Item3'),
    ]
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row pb--125">
            <Breadcrumb
              title={t('dataTitle')}
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Data"
              styles={styles.bg}
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <Title
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "data"
                    title = "Solutions"
                    description = {t('dataBody')}
                  />
                </div>
              </div>
              {/* first */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="/images/data/sellout.jpg" alt={forecast.title} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{forecast.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: forecast.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {forecast.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="forecast" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second */}
              <div className="rn-splite-style">
                <div className="split-wrapper">
                  <div className="row no-gutters radius-10 align-items-center reverse">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{service.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: service.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {service.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">                            
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="recommend" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="/images/data/recommend.jpg" alt={service.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Third */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="https://storage.googleapis.com/alphacode-company-image/fraud.jpg" alt={data.title} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{data.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: data.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {data.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="fraud" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fourth */}
              <div className="rn-splite-style">
                <div className="split-wrapper">
                  <div className="row no-gutters radius-10 align-items-center reverse">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <h4 className="title">{dap.title}</h4>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <p className="description" dangerouslySetInnerHTML={{__html: dap.body}}></p>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}>
                          <ul className="split-list">
                          {dap.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        </ScrollAnimation>
                        <ScrollAnimation 
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          animateOnce={true}
                          className="mt--10">                            
                          <Link className="btn-default btn-small btn-border btn-icon" to="#" data-param="dap" onClick={openLayer}>View More <i className="icon"><FiArrowRight /></i></Link>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className="thumbnail image-left-content">
                          <img src="https://storage.googleapis.com/alphacode-company-image/dap.png" alt={dap.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div className="flex-center ptb--50">
                  <a className="btn-default round" href="/offering"><span>{t('viewOffering')}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Data
